import React from "react";

export const ManageAnniversaryIcon = () => (
  <React.Fragment>
    {" "}
    <path d="M5.48556 10.2793L3.375 15.9089V15.9093C3.89147 17.9726 5.02667 19.7966 6.63366 21.1724L9.28174 20.1794C6.46905 17.6602 5.08077 13.9547 5.48556 10.2793Z" fill="#007AB1"/>
    <path d="M2.65997 17.8135L1.04224 22.127C0.848887 22.6422 1.3529 23.1518 1.87194 22.9567L5.28485 21.6771C4.14011 20.5661 3.2521 19.242 2.65997 17.8135Z" fill="#007AB1"/>
    <path d="M10.0099 9.75438L7.60414 7.34863C5.65358 11.7486 6.98708 16.7777 10.6673 19.6601L13.235 18.6972C10.9799 16.3684 9.7602 13.1178 10.0099 9.75438Z" fill="#007AB1"/>
    <path d="M11.2676 11.0146C11.3664 13.7495 12.5575 16.334 14.5726 18.1967H14.5731L16.455 17.4911C16.8946 17.3261 17.0149 16.762 16.6845 16.4316L11.2676 11.0146Z" fill="#007AB1"/>
    <path d="M15.9759 13.0375C16.2276 13.2892 16.6357 13.2892 16.8874 13.0375C19.3121 10.6128 21.6731 11.3624 21.6967 11.3703C22.0344 11.4828 22.3994 11.3003 22.512 10.9626C22.6246 10.625 22.442 10.2599 22.1044 10.1474C21.9773 10.105 18.9578 9.14419 15.9759 12.1261C15.7242 12.3777 15.7242 12.7859 15.9759 13.0375Z" fill="#007AB1"/>
    <path d="M11.8757 8.02513C14.8576 5.04361 13.8965 2.02386 13.8544 1.89667C13.7418 1.55895 13.3766 1.37677 13.0388 1.48934C12.7015 1.60192 12.5189 1.96671 12.6315 2.30444C12.6392 2.32807 13.389 4.68913 10.9639 7.11379C10.7138 7.3639 10.7113 7.77171 10.9639 8.02513C11.217 8.27812 11.6247 8.27615 11.8757 8.02513Z" fill="#007AB1"/>
    <path d="M13.2415 9.39213C12.9898 9.64384 12.9898 10.0519 13.2415 10.3036C13.4909 10.553 13.8961 10.5563 14.1492 10.3074C14.4821 10.1415 15.5156 10.7638 16.0899 10.1897C16.6581 9.62145 16.0544 8.60901 16.2052 8.25418C16.5605 8.10302 17.5725 8.70697 18.1407 8.13886C18.709 7.57066 18.1053 6.55821 18.2561 6.20334C18.6119 6.05214 19.6234 6.65618 20.1915 6.08802C20.7598 5.51982 20.1561 4.50737 20.3069 4.1525C20.6606 4.00208 21.6747 4.60474 22.2425 4.03714C22.8166 3.46296 22.1955 2.42766 22.3602 2.09642C22.6081 1.84446 22.6068 1.43924 22.3563 1.18878C22.1047 0.937074 21.6966 0.937074 21.4449 1.18878C20.9369 1.69678 21.0362 2.41395 21.1319 2.92668C20.6192 2.831 19.9021 2.73161 19.3941 3.23957C18.8861 3.74758 18.9854 4.46475 19.0811 4.97748C18.5684 4.88183 17.8512 4.78236 17.3432 5.29041C16.8352 5.79842 16.9347 6.51559 17.0303 7.02832C16.5175 6.93263 15.8004 6.8332 15.2924 7.34121C14.7844 7.84921 14.8838 8.56638 14.9795 9.07911C14.4667 8.98351 13.7495 8.88413 13.2415 9.39213Z" fill="#007AB1"/>
    <path d="M16.8874 3.92154C17.1391 3.66983 17.1391 3.26177 16.8874 3.01007C16.6357 2.75836 16.2276 2.75836 15.9759 3.01007C15.7242 3.26177 15.7242 3.66983 15.9759 3.92154C16.2276 4.17324 16.6357 4.17324 16.8874 3.92154Z" fill="#007AB1"/>
    <path d="M20.9902 8.66988C21.3462 8.66988 21.6347 8.38133 21.6347 8.02537C21.6347 7.66942 21.3462 7.38086 20.9902 7.38086C20.6343 7.38086 20.3457 7.66942 20.3457 8.02537C20.3457 8.38133 20.6343 8.66988 20.9902 8.66988Z" fill="#007AB1"/>
    <path d="M19.6204 13.9485C19.3687 14.2002 19.3687 14.6083 19.6204 14.86C19.8721 15.1117 20.2802 15.1117 20.5319 14.86C20.7836 14.6083 20.7836 14.2002 20.5319 13.9485C20.2802 13.6968 19.8721 13.6968 19.6204 13.9485Z" fill="#007AB1"/>
    <path d="M10.5065 4.8356C10.7582 4.5839 10.7582 4.17583 10.5065 3.92413C10.2548 3.67243 9.84673 3.67243 9.59503 3.92413C9.34332 4.17579 9.34332 4.5839 9.59503 4.8356C9.84673 5.0873 10.2548 5.0873 10.5065 4.8356Z" fill="#007AB1"/>
  </React.Fragment>
);

export const ManageCoreValueIcon = () => (
  <React.Fragment>
    {" "}
    <path id="Vector" d="M8.82422 7.56164H15.1715L11.9979 2.00781L8.82422 7.56164Z" fill="#007AB1"/>
    <path id="Vector_2" d="M16.959 7.56152H23.9997C23.9744 7.51733 23.9459 7.47508 23.9144 7.43512L20.0537 2.60938L16.959 7.56152Z" fill="#007AB1"/>
    <path id="Vector_3" d="M16.0664 7.08234L19.2344 2.01353C19.1836 2.00507 19.1322 2.00055 19.0806 2H13.1621L16.0664 7.08234Z" fill="#007AB1"/>
    <path id="Vector_4" d="M8.59766 8.57129L11.995 22.1606L15.3924 8.57129H8.59766Z" fill="#007AB1"/>
    <path id="Vector_5" d="M16.4408 8.57129L13.2617 21.2876L23.8966 8.71906C23.9356 8.67299 23.9703 8.62354 24.0004 8.57129H16.4408Z" fill="#007AB1"/>
    <path id="Vector_6" d="M7.55962 8.57129H0C0.0301378 8.62345 0.0648681 8.67282 0.103778 8.7188L10.7387 21.2876L7.55962 8.57129Z" fill="#007AB1"/>
    <path id="Vector_7" d="M7.93761 7.08234L10.8419 2H4.92337C4.87184 2.00025 4.82041 2.00448 4.76953 2.01264L7.93761 7.08234Z" fill="#007AB1"/>
    <path id="Vector_8" d="M7.04136 7.56152L3.9466 2.60938L0.085323 7.43512C0.053792 7.47508 0.0252726 7.51733 0 7.56152H7.04136Z" fill="#007AB1"/>
  </React.Fragment>
);

export const ComparisonIcon = ()=>(
  <React.Fragment>
    <path id="Vector" d="M11.7579 9.06651C13.4397 9.06651 14.7911 7.71506 14.7911 6.03326C14.7911 4.35145 13.4397 3 11.7579 3C10.0761 3 8.72461 4.35145 8.72461 6.03326C8.72461 7.71506 10.0761 9.06651 11.7579 9.06651Z" fill="#007AB1"/>
    <path id="Vector_2" d="M21.7574 12.5803H18.2136L15.1804 10.2979C14.9702 10.1477 14.7299 10.0576 14.4896 10.0576H8.99374C8.75348 10.0576 8.4832 10.1477 8.303 10.2979L5.26975 12.5803H1.72594C1.09527 12.5803 0.554688 13.0909 0.554688 13.7516C0.554688 14.3823 1.06524 14.9228 1.72594 14.9228H5.66017C5.90042 14.9228 6.17071 14.8327 6.35091 14.6826L8.3931 13.1209V21.0194C8.3931 21.0194 8.99374 21.4454 9.74455 21.4454C10.4954 21.4454 11.096 21.0194 11.096 21.0194H12.3574C12.3574 21.0194 12.958 21.4454 13.7088 21.4454C14.4596 21.4454 15.0603 21.0194 15.0603 21.0194V13.1209L17.1024 14.6826C17.3127 14.8327 17.5529 14.9228 17.7932 14.9228H21.7274C22.3581 14.9228 22.8987 14.4123 22.8987 13.7516C22.9287 13.0909 22.3881 12.5803 21.7574 12.5803Z" fill="#007AB1"/>
    <path id="Vector_3" d="M1.51663 11.4687H3.85914C3.97927 11.4687 4.0994 11.4087 4.15946 11.2885L5.33072 9.27637C5.39078 9.15624 5.39078 9.03611 5.33072 8.91599L4.15946 6.90383C4.0994 6.7837 3.97927 6.72363 3.85914 6.72363H1.51663C1.3965 6.72363 1.27637 6.7837 1.21631 6.90383L0.0450483 8.88595C-0.0150161 9.00608 -0.0150161 9.12621 0.0450483 9.24634L1.21631 11.2585C1.27637 11.4087 1.3965 11.4687 1.51663 11.4687Z" fill="#007AB1"/>
    <path id="Vector_4" d="M23.9507 10.8084L21.5782 6.69399C21.398 6.39367 21.0076 6.39367 20.8274 6.69399L18.4548 10.8084C18.2747 11.1087 18.4849 11.4691 18.8152 11.4691H23.5603C23.8907 11.4691 24.1009 11.1087 23.9507 10.8084Z" fill="#007AB1"/>
  </React.Fragment>
)
